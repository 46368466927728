<template>
  <div v-title :data-title="`${this.$headToUpperCase} Game Types ——${gameType}`">
    <div v-if="contentType" class="irIQZt">
      <div class="sc-1bi8huj-0 iRQTOz">
        <PCLogo whereFrom="3" portal="pc_tab"></PCLogo>
        <div class="title">
          <h1>{{gameType}}</h1>
        </div>
        <div style="display: contents">
          <div class="rightAdv" style="grid-area: rightAdv">
            <div class="iFmTCx">
              <div class="eDhvMX adv" style="height: 250px; width: 300px; overflow: hidden;">
                <div v-if="noAfc" :id="gtpRightId" ref="rightAdv"></div>
                <ins v-if="!noAdvertisements && !noAfc" class="adsbygoogle"
                     style="display:block;width: 300px;height: 250px"
                     :data-ad-client="caPub"
                     :data-ad-slot="rightSlot"
                     data-ad-format="true"
                     data-full-width-responsive="true"></ins>
              </div>
              <div class="iZJgLq">advertisement</div>
            </div>
          </div>
          <div class="bottomAdv" style="grid-area: bottomAdv">
            <div class="iFmTCx">
              <div :style="{width: '728px',height: '90px',overflow: 'hidden'}" class="eDhvMX adv">
                <div v-if="noAfc" :id="gtpBottomId" ref="leftAdv"></div>
                <ins v-if="!noAdvertisements && !noAfc" class="adsbygoogle"
                     style="display:block;width: 728px;height: 90px"
                     :data-ad-client="caPub"
                     :data-ad-slot="bottomSlot"
                     data-ad-format="true"
                     data-full-width-responsive="true"></ins>
              </div>
            </div>
          </div>
        </div>
        <div class="content" style="display: contents">
          <a :href="'/P/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')"
             class="sc-wr3rvk-0 app-item"
             v-for="(item,index) in gameList"
             :key="index"
             @click="iconClick(item)"
             :style="(innerWidth >= 1871 && index <= 13) ||
             ((innerWidth >= 1541 && innerWidth <= 1870.9) && index <= 9) ||
             ((innerWidth >= 1321 && innerWidth <= 1540.9 || innerWidth >= 1211 && innerWidth <= 1320.9) && index <= 7) ||
              ((innerWidth >= 991 && innerWidth <= 1210.9) && index <= 5) ||
              ((innerWidth >= 111 && innerWidth <= 990.9) && index <= 3) ? {gridArea: 'bigIp' + index,width: '204px',height: '204px'} : null"
          >
            <img :src="item.iconUrl" alt="">
            <span class="sc-963fcq-0 esaxGV global-cq-title">{{item.Name}}</span>
          </a>
        </div>
      </div>
      <div class="segmentation">More Games For You</div>
      <div class="recommend">
        <a :href="'/P/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')" class="sc-wr3rvk-0 app-item" v-for="(item,index) in recommend" :key="index" @click="iconClick(item)">
          <img :src="item.iconUrl" alt="">
          <span class="sc-963fcq-0 esaxGV global-cq-title">{{item.Name}}</span>
        </a>
      </div>
    </div>
    <div v-if="contentType" class="type-list">
      <TypeList whereFrom="4"></TypeList>
      <div class="bottom-text">
        <nav class="about">
          <ul>
            <li><a :href="'/P/homeIndex' + ($route.query.channel ? ('?channel='+$route.query.channel): '')" @click="goHome">Games</a></li>
            <li>{{gameType}} games</li>
          </ul>
        </nav>
        <div class="title"><h2>{{gameType.slice(0,1).toUpperCase() + gameType.slice(1).toLowerCase()}} Games</h2></div>
        <div v-if="gameType == 'SHOOTING'">
          <p>Expensive video game consoles aren’t the only place to play marksmanship games. Our collection of shooting games are all free to play and available right now, on your computer. Play as a variety of marksmen in tons of environments, sneaking through levels and firing at any enemies in your path. Create classes with all kinds of different guns. Play as a futuristic assassin with ultra-modern weapons or go back in time and re-live the Doom game series. In our shooting challenges, you can holster your weapon as a solo player or unleash ammo as a member of a multiplayer task force. The possibilities are endless.</p>
          <p>Play now our online Shooting Games for free on your PC. You can play the Shooting Games in your browser without having to download them. Most of our games can also be played on a mobile phone or tablet. Have fun playing the best shooters here on {{$originCopyWriting}}!</p>
          <h3>How to play Shooting Games?</h3>
          <p>All our shooting can be controlled with keyboard and mouse. First look into the description of the games, often you can find the controls there. Else these are the most commonly used controls for the PC:</p>
          <ul>
            <li>WASD - Move Around</li>
            <li>Mouse - Aim</li>
            <li>Left Mouse Click - Shoot</li>
            <li>Right Mouse Click - Zoom In</li>
            <li>Spacebar - Jump</li>
            <li>Shift - Sprint</li>
            <li>R - Reload</li>
          </ul>
          <p>Some of the shooting games can also be played on a mobile phone or tablet. Then you control everything with your touchscreen.</p>
        </div>
        <div v-if="gameType == 'CAR'">
          <p>Whether you enjoy race cars, dune buggies, or motorcycles, we have the perfect car challenge for you. Go from 0 to 60 mph in mere seconds in one of our racing challenges, and try to set a new speed record. Or, choose an off-road vehicle and traverse over mountains, junkyards, and other exotic terrains. Even go through hundreds of options and customize your very own vehicle in our car games! After creating the ideal ride, take your racer on the road and challenge tough opponents. A full selection of go kart and parking challenges are included, too!</p>
          <p>Some of our free car games can even be played in 3D! The games are easy to control and fun for all kinds of players. Play all of our car games online on your PC and use your keyboard to accelerate, brake, and steer your vehicle. Some games allow you to modify the environment and balance your vehicle with a simple press of the arrow keys. Some of our car games can also be played on the mobile phone or tablet and are controlled with the touchscreen. Hours and hours of countless fun are at your fingertips, with a little-to-no learning curve! Wield the power of a gigantic monster truck or race across tracks at 200+ mph in an exotic sport car. For a strategic challenge, try squeezing your car into a tight spot in a parking game variant!</p>
        </div>
        <div v-if="gameType == 'BALL'">
          <p>Compete against the best athletes in the world, and win grueling championship tournaments! Our ball games include every type of incredible sport, including football, basketball, and baseball. Now, you have the chance to play like Pelé in the World Cup! Or, pretend you're Michael Jordan, and score against the best NBA teams. Our baseball challenges include backyard games and home run derby competitions. Instead of simply watching sports on TV, you should play them right here!</p>
          <p>Our ball games will make you feel like a real superstar. On the gridiron, you can throw touchdown passes like a real NFL quarterback. As a soccer all-star, you have the ability to score free kicks and help your goalkeeper make amazing saves. Every game in our collection teaches you how to kick, pass, toss, and shoot like Hall of Fame athletes. Win the playoffs to take home the gold medal!</p>
        </div>
        <div v-if="gameType == 'GIRLS'">
          <p>Our vast collection of girl games is sure to entertain you. You can play any type of game, from simple dress-up to advanced dance competitions. Control cute characters and admirable women. Show off your extreme gaming skills, or relax with fashion-based games. For a romantic adventure, pick a cute date, and fall in love! Create outfits for celebrities, design dresses for dolls, or apply cute makeup. Fulfill your wildest shopping dreams without spending a penny.</p>
          <p>Our games for girls collection features all sorts of licensed dolls and characters. You can play games featuring Barbie and Bratz dolls, and even play with replicas of your own toys! Bring your toy chest to life with highly authentic, virtual characters. For a classic, girly challenge, try one of our adorable Holly Hobbie or Hello Kitty adventures. Try playing with cats and dogs, and take care of your own pet! From Sue to Winx to Nick Jr.'s Dora the Explorer, our playful collection will make every girl happy.</p>
        </div>
        <div v-if="gameType == 'CASUAL'">
          <p>Casual games are usually the most relaxing and enjoyable games to kill time. They usually offer some easy but not so hard challenges, so you can relax and enjoy a relaxing time.</p>
          <p>Our casual games are rich in variety and full of creativity. There are fun and stress-relieving elimination games, relaxing and enjoyable bubble shooters, and many agility games that test your reflexes. Graffiti, puzzles, puzzles, dress-up, etc. are all here, you won't be bored.</p>
        </div>
        <div v-if="gameType == 'PUZZLE'">
          <p>Stunning graphics and smooth action are included in all of our puzzle games. Your strategic skills and puzzle-solving abilities will be put to the test, as you try to complete one of thousands of different challenges! In our challenges, you can build bridges, match moving shapes, and define routes to win. Solve 3D Rubik’s Cubes, challenge the computer in Tic-Tac-Toe, and cause chain reactions in our collection! Stimulate your brain today, and dive into a puzzling challenge. We have Orbox, rotation games, and much more waiting for you!</p>
          <p>A mouse and keyboard are all you need to solve our many exciting puzzles. Our collection includes adventures for every type of player! You can enjoy arcade-style 2D graphics or neon-fused 3D artwork in our puzzle games. Work with Rube Goldberg devices, solve engineering mysteries, and put your problem-solving skills to the test. After diving into our mind-bending collection, you'll never be bored ever again!</p>
        </div>
        <div v-if="gameType == 'ACTION'">
          <h4>For the fighter, a thirst for battle!</h4>
          <p>Satisfying guns, explosive gameplay and a randomized weapon system - that's Action Games! No matter if you're playing Mini Royale, Repuls.io or any other Action Game - the epic journey awaits you in this unique genre.</p>
          <p>Action Games brings mobile games where hand-to-hand combat is king. Featuring games that will get your blood pumping with an exciting mix of hack-and-slash, war and action themes, you'll never be at a loss for the next saga.</p>
          <p>These top-rated action games are designed to be challenging. Whether you're looking for an adrenaline rush or a casual arcade game, you'll find something that fits your style.</p>
          <p>Hundreds of action games are out there, and we've covered all of the best ones for mobile. Some action games are multiplayer, while others have huge maps you have to explore. Some are puzzle based, and others include epic graphics and cool props. Action games may be 3D based or 2D, but they will always give you an adrenaline rush.</p>
          <p>Action Games are great for anyone that likes to act cool by engaging in fast paced battles, using melee and ranged attacks to defeat their opponents. Collect items on the battlefield, upgrade your weapons and go for headshots and critical hits to take down your foes.</p>
        </div>
        <div v-if="gameType == 'RACING'">
          <p>Ride in all types of different vehicles in our racing games! Take the traditional route, and drive a sports car on a racetrack. Or, weave through traffic on a busy city course! You can hit the streets with your friends, or compete with other racers online. For a slower challenge, pedal your way through a bicycle racing game. Race in tons of different environments! After conquering courses on land, take a boat into the water! Learn how to master any mode of transportation, and always go for the gold!</p>
          <p>Navigate racetracks in our collection with ease! Peel out from the start, skid around turns, and speed through straightaways. Many of our racing games put you in the driver's seat, so you can look right through the dashboard! Enjoy realistic cockpit graphics, panoramic views, and responsive controls. Use mirrors to get a better angle, and hurry to the finish! Drift behind your opponents, catch a tailwind, and glide to the front of the pack. Finish in first place in a dirt race, on water, or through the sky!</p>
        </div>
        <div v-if="gameType == 'PARKOUR'">
          <p>Run, flip, and wall-jump through amazing urban courses in our parkour games! Based on military training through obstacle courses, the art of parkour requires total body control. You will face all sorts of barriers in each course. Figure out the best way to move without stopping, and get past every obstacle! Learn how to move like the best free-runners in the world, and win the championship!</p>
          <p>Our parkour games are filled with realistic and cartoon challenges! Play as Faith from Mirror's Edge, and go on a dangerous journey through high-security areas. Run across rooftops, flip over gaps, and sprint to survive! Each athlete is a master of free-running. Your job is to take control and complete entire courses without getting caught. Train with the best competitors from American Ninja Warrior!</p>
        </div>
        <div v-if="gameType == '3D'">
          <p>Our 3D games are highly addictive and fun for all players. If you enjoy great graphics and fast action, you’ll love our 3-dimensional games! You can modify star constellations, create your own images, and even construct lifelike pictures. Our collection features hundreds of challenging levels and images. Re-create realistic star patterns, unscrambled jumbled images, and earn thousands of points! Solve each puzzle quickly enough, and you can advance to the next level and earn an admirably high score!</p>
          <p>You’ll learn to play our 3D games in a matter of seconds; just use your mouse and manipulate the image into the correct pattern. Our 3-dimensional games feature in-game tutorials, guiding you step-by-step through the first few levels. After this mini-training session, you’ll be on your way to becoming an expert! Choose your favorite adventure, which features several different environment options; play in outer space or in an abstract video game land! Take delight in the smooth gameplay and vibrant graphics in our collection!</p>
        </div>
        <div v-if="gameType == 'ADVENTURE'">
          <p>Our collecting of adventure gaming is perfect for highly skilled and newbie players alike. Enjoy simple Point 'n Click challenges, try wild, action-packed games, or play full-scale RPGs! Travel from your country to other continents, and even venture into outer space! With thousands of adventure games available, you can partake in addictive journeys for countless hours! Ride rollercoasters, don't get caught, and battle with dangerous pirates! Play modded, computer versions of your favorite console games, or try totally unique Flash games!</p>
          <p>Our adventure collection includes games from dozens of different genres. Music fans can learn to play to instruments and create songs, while movie buffs can reenact scenes from their favorite films! Play the role of business owner, and run a bustling restaurant in a management-style game. Choose a character in each challenge to represent you or one of your heroes.  Solve mysteries in point 'n click challenges, and compete with players around the world. Make key decisions, and experience a brand new journey every time in our adventure games!</p>
        </div>
        <div v-if="gameType == 'ARCADE'">
          <p>Our collection is an amalgam of the greatest arcade games ever made. We have older and brand new machines, from original Pong to 3D air hockey! In our challenging levels, you’ll experience exciting action and captivating gameplay. Play modern variations of Space Invaders and Bomberman, complete with authentic game sounds and graphics. Or, play updated Nintendo and Sega games, including Mario and Sonic the Hedgehog. All of your arcade desires are waiting to be fulfilled.</p>
          <p>Our collection also includes less conventional games, which are just as entertaining. Play Final Fantasy modifications, WALL-E themed games, and much more. Regardless of the adventure you choose, our arcade games feature easy-to-learn controls. Use your keyboard and mouse to shoot lasers, move characters, and hop across giant gaps. Earn points, virtual money, and compete against others in our 8-bit challenges. Start playing today and become a master of the virtual arcade.</p>
        </div>
        <div v-if="gameType == 'SPORTS'">
          <p>Athletes, fans, and all-star gamers will love our collection of sports games! You can play 8- or 9-ball billiards against the computer, box with your friends, and ride galloping horses. Play team games like soccer and baseball, and try to win league tournaments. Relax with a skilled, leisure game, or head into extreme territory. Play a whole slew of winter sports, including skiing, sledding, and downhill toboggan challenges. Go head-to-head against difficult AI, or compete with challenging, athletic players around the world.</p>
          <p>Compete on land, in the water, or high in the sky! Play all types of sports games, from traditional football to extreme base-jumping. Control your player's power in each game, and use it to defeat your opponents! Throw punches, perform tackles, and leap through the air with aggression. Perform tricks with a trampoline master, or backflip over snowy ramps. Practice using effective teamwork in multiplayer challenges, and work on your own skills in head-to-head challenges. Win individual games to dominate online tournaments.</p>
        </div>
        <h3>What are the best free Shooting Games online?</h3>
        <ol>
          <li v-for="(item,index) in gameList.slice(0,10)" :key="index">
            <a :href="'/P/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(item)">
              {{item.Name}}
            </a>
          </li>
        </ol>
        <h3>What are the most popular Shooting Games for the mobile phone or tablet?</h3>
        <ol>
          <li v-for="(item,index) in gameList.slice(0,5)" :key="index">
            <a :href="'/P/details/'+item.Name.replace(/\s+/g, '') + '?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(item)">
              {{item.Name}}
            </a>
          </li>
        </ol>
      </div>
    </div>
    <div style="display: contents" v-if="smegmaType">
      <div class="AFSJx dgmlwU" style="background-color: #ffffff">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import TypeList from '@/components/TypeList.vue';
import SearchFor from '@/components/SearchFor.vue';
import PCLogo from "@/components/PCLogo.vue";
import {
  clickGameLog, clickSearchLog, detailsPageOutLog,
  determinePcOrMove, iconClickEscalation,
  Observer,
  pageInitLog,
  pageOutLog,
  recentGame,
  shuffle
} from '@/utils/utils.js'
import logo from '@/assets/logo.png'
import home from '@/assets/home.png'
import souSuo from '@/assets/sousuo.png'
export default {
  name: "gameIndex",
  components: {
    TypeList, SearchFor, PCLogo
  },
  data() {
    return {
      logo,home,souSuo,
      gameType: "", // title
      gameList: [], //
      recommend: [], // 推荐
      UnfoldAndCollapse: false, // 展开收起
      smegmaType: false,
      contentType: false,
      innerWidth: null,
      noAdvertisements: null, // 1没广告
      noAfc: null, // 1gtp
      caPub: '',
      rightSlot: '',
      bottomSlot: '',
      gtpRightId: '',
      gtpBottomId: '',
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { channel_ca_pub, p_type_right, p_type_bottom, noAdvertisements, noAfc, p_type_gpt_right, p_type_gpt_left } = channelInfo || {}
    this.caPub = channel_ca_pub
    this.rightSlot = p_type_right
    this.bottomSlot = p_type_bottom
    this.noAdvertisements = noAdvertisements
    this.noAfc = noAfc
    let gtpR = p_type_gpt_right && p_type_gpt_right.split(",") || []
    let gtpB = p_type_gpt_left && p_type_gpt_left.split(",") || []
    this.gtpRightId = gtpR[3]
    this.gtpBottomId = gtpB[3]
  },
  mounted() {
    document.documentElement.scrollTop = 0
    const { query } = this.$route
    const { gameType } = query || {}
    if (determinePcOrMove() == 1) {
      let { channel } = this.$route.query
      this.$router.push({
        path: '/M/gameType',
        query: {
          gameType,
          channel
        }
      },()=>{})
    } else {
      let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
      const { noAdvertisements, noAfc } = channelInfo || {}

      this.smegmaType = true
      // 进入页面埋点
      pageInitLog('pc_tab')
      this.timer = setInterval(()=>{
        if (window.loadOk) {
          clearInterval(this.timer)
          this.smegmaType = false
          this.contentType = true
          this.getGameList()
          if (!noAdvertisements && !noAfc) {
            setTimeout(()=>{
              window.addAds()
            },500)
          }
          if (noAfc) {
            // 加载广告
            if (window.isDisplay) {
              googletag.cmd.push(function() { googletag.pubads().refresh(); });
            }

            let leftAdv = document.createElement("script")
            leftAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(typePcl[3] ? typePcl[3] : 'div-gpt-ad-1701941128753-0');};});"
            setTimeout(()=>{
              this.$refs.leftAdv.append(leftAdv)
            },600)

            let rightAdv = document.createElement("script")
            rightAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(typePcr[3] ? typePcr[3] : 'div-gpt-ad-1701941223156-0');window.isDisplay = true};});"
            setTimeout(()=>{
              this.$refs.rightAdv.append(rightAdv)
            },1000)
            // 获取需要曝光的item
            setTimeout(()=>{
              let itemArr = [...document.getElementsByClassName("sc-wr3rvk-0")]
              itemArr && Array.from(itemArr).map((item)=>{
                Observer('pc_tab').observe(item)
              })
            })
          }
        }
      },800)
    }
  },
  methods:{
    goHome() {
      pageOutLog('pc_tab')
    },
    // 获取游戏列表
    getGameList() {
      this.innerWidth = window.innerWidth
      let innerWidth = window.innerWidth
      let arr = shuffle(getAllJson()) || []
      window.onresize = () => {
        console.log(window.innerWidth);
        this.innerWidth = window.innerWidth
        let innerWidth1 = window.innerWidth
        console.log(innerWidth1);
        this.pushItem(innerWidth1,arr)
      }
      this.pushItem(innerWidth,arr)
    },
    pushItem(innerWidth,arr) {
      const { query } = this.$route
      const { gameType } = query || {}
      this.gameType = gameType
      let gameArr = getAllJson()
      let gameList = []
      gameArr && gameArr.map((item)=>{
        if (item[gameType] == 1) {
          gameList.push(item)
        }
      })
      if (innerWidth >= 1871) {
        console.log(gameList.length);
        arr.map((item)=>{
          if (gameList.length < 39) {
            gameList.push(item)
          }
        })
      }
      if (innerWidth >= 1541 && innerWidth <= 1870.9) {
        console.log(gameList.length);
        arr.map((item)=>{
          if (gameList.length < 34) {
            gameList.push(item)
          }
        })
      }
      if (innerWidth >= 1321 && innerWidth <= 1540.9) {
        console.log(gameList.length);
        arr.map((item)=>{
          if (gameList.length < 28) {
            gameList.push(item)
          }
        })
      }
      if (innerWidth >= 1211 && innerWidth <= 1320.9) {
        console.log(gameList.length);
        arr.map((item)=>{
          if (gameList.length < 22) {
            gameList.push(item)
          }
        })
      }
      if (innerWidth >= 991 && innerWidth <= 1210.9) {
        console.log(gameList.length);
        arr.map((item)=>{
          if (gameList.length < 16) {
            gameList.push(item)
          }
        })
      }
      if (innerWidth >= 111 && innerWidth <= 990.9) {
        console.log(gameList.length);
        arr.map((item)=>{
          if (gameList.length < 10) {
            gameList.push(item)
          }
        })
      }
      this.gameList = gameList
      this.recommend = arr.slice(0,50)
    },
    // 切换游戏
    iconClick (item) {
      clickGameLog('pc_tab', item)
      recentGame(item)
      // 离开页面埋点
      pageOutLog('pc_tab')
      if (this.$originCopyWriting == 'ajoy') {
        bge && bge('event', 'button', {configId: '903985143796275456'});
        sessionStorage.setItem('pixelType','1')
      }

      iconClickEscalation()
    },
  },
  // beforeRouteLeave(to, from, next){
  //   console.log(to);
  //   console.log(from);
  //   if (to.query.gameId) {
  //     getAllJson().map((item)=>{
  //       if (item.gameId == to.query.gameId) {
  //         clickGameLog('pc_tab', item)
  //         recentGame(item)
  //         if (this.$originCopyWriting == 'ajoy') {
  //           bge && bge('event', 'button', {configId: '903985143796275456'});
  //           sessionStorage.setItem('pixelType','1')
  //         }
  //       }
  //     })
  //   }
  //   next()
  // },
  beforeDestroy() {
    // 离开页面埋点
    pageOutLog('pc_tab')
  },
  watch: {
    '$route'(val) {
      console.log(val,'数据更新了');
      document.documentElement.scrollTop = 0
      this.getGameList()
    }
  }
}
</script>

<style lang="less" scoped>
@media (min-width: 1871px) {
  .type-list{
    width: 1854px!important;
  }
  .irIQZt {
    width: 1854px!important;
    .iRQTOz{
      --gridTemplateColumns: 17;
      grid-template-areas:
        ". . ibx ibx ibx . . . . . . . . . . . ."
        "bigIp0 bigIp0 bigIp1 bigIp1 bigIp2 bigIp2 bigIp3 bigIp3 bigIp4 bigIp4 bigIp5 bigIp5 bigIp6 bigIp6 rightAdv rightAdv rightAdv"
        "bigIp0 bigIp0 bigIp1 bigIp1 bigIp2 bigIp2 bigIp3 bigIp3 bigIp4 bigIp4 bigIp5 bigIp5 bigIp6 bigIp6 rightAdv rightAdv rightAdv"
        "bigIp7 bigIp7 bigIp8 bigIp8 bigIp9 bigIp9 bigIp10 bigIp10 bigIp11 bigIp11 bigIp12 bigIp12 bigIp13 bigIp13 rightAdv rightAdv rightAdv"
        "bigIp7 bigIp7 bigIp8 bigIp8 bigIp9 bigIp9 bigIp10 bigIp10 bigIp11 bigIp11 bigIp12 bigIp12 bigIp13 bigIp13 . . ."
        ". . . . . bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv . . . . ."
    }
    .iRQTOz::before{
      grid-column-start: span 2;
    }
  }
}
@media (min-width: 1541px) and (max-width: 1870.9px) {
  .type-list{
    width: 1524px;
  }
  .irIQZt {
    width: 1524px;
    .iRQTOz{
      --gridTemplateColumns: 14;
      grid-template-areas:
        ". ibx ibx ibx . . . . . . . . . ."
        "bigIp0 bigIp0 bigIp1 bigIp1 bigIp2 bigIp2 bigIp3 bigIp3 bigIp4 bigIp4 . rightAdv rightAdv rightAdv"
        "bigIp0 bigIp0 bigIp1 bigIp1 bigIp2 bigIp2 bigIp3 bigIp3 bigIp4 bigIp4 . rightAdv rightAdv rightAdv"
        "bigIp5 bigIp5 bigIp6 bigIp6 bigIp7 bigIp7 bigIp8 bigIp8 bigIp9 bigIp9 . rightAdv rightAdv rightAdv"
        "bigIp5 bigIp5 bigIp6 bigIp6 bigIp7 bigIp7 bigIp8 bigIp8 bigIp9 bigIp9 . . . ."
        ". . . . . bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv . ."
    }
  }
}
@media (min-width: 1321px) and (max-width: 1540.9px) {
  .type-list{
    width: 1304px;
  }
  .irIQZt {
    width: 1304px;
    .iRQTOz{
      --gridTemplateColumns: 12;
      grid-template-areas:
        ". ibx ibx ibx . . . . . . . ."
        "bigIp0 bigIp0 bigIp1 bigIp1 bigIp2 bigIp2 bigIp3 bigIp3 . rightAdv rightAdv rightAdv"
        "bigIp0 bigIp0 bigIp1 bigIp1 bigIp2 bigIp2 bigIp3 bigIp3 . rightAdv rightAdv rightAdv"
        "bigIp4 bigIp4 bigIp5 bigIp5 bigIp6 bigIp6 bigIp7 bigIp7 . rightAdv rightAdv rightAdv"
        "bigIp4 bigIp4 bigIp5 bigIp5 bigIp6 bigIp6 bigIp7 bigIp7 . . . ."
        ". . . bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv . ."
    }
  }
}
@media (min-width: 1211px) and (max-width: 1320.9px) {
  .type-list{
    width: 1194px;
  }
  .irIQZt {
    width: 1194px;
    .iRQTOz{
      --gridTemplateColumns: 11;
      grid-template-areas:
        ". ibx ibx ibx . . . . . . ."
        "bigIp0 bigIp0 bigIp1 bigIp1 bigIp2 bigIp2 bigIp3 bigIp3 rightAdv rightAdv rightAdv"
        "bigIp0 bigIp0 bigIp1 bigIp1 bigIp2 bigIp2 bigIp3 bigIp3 rightAdv rightAdv rightAdv"
        "bigIp4 bigIp4 bigIp5 bigIp5 bigIp6 bigIp6 bigIp7 bigIp7 rightAdv rightAdv rightAdv"
        "bigIp4 bigIp4 bigIp5 bigIp5 bigIp6 bigIp6 bigIp7 bigIp7 . . ."
        ". . bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv . ."
    }
  }
}
@media (min-width: 991px) and (max-width: 1210.9px) {
  .type-list{
    width: 980px;
  }
  .irIQZt {
    width: 980px;
    .iRQTOz{
      --gridTemplateColumns: 9;
      grid-template-areas:
        ". ibx ibx ibx . . . . ."
        "bigIp0 bigIp0 bigIp1 bigIp1 bigIp2 bigIp2 rightAdv rightAdv rightAdv"
        "bigIp0 bigIp0 bigIp1 bigIp1 bigIp2 bigIp2 rightAdv rightAdv rightAdv"
        "bigIp3 bigIp3 bigIp4 bigIp4 bigIp5 bigIp5 rightAdv rightAdv rightAdv"
        "bigIp3 bigIp3 bigIp4 bigIp4 bigIp5 bigIp5 . . ."
        ". bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv ."
    }
  }
}
@media (min-width: 111px) and (max-width: 990.9px) {
  .type-list{
    width: 760px;
  }
  .irIQZt {
    width: 760px;
    .iRQTOz{
      --gridTemplateColumns: 7;
      grid-template-areas:
        ". ibx ibx ibx . . ."
        "bigIp0 bigIp0 bigIp1 bigIp1 rightAdv rightAdv rightAdv"
        "bigIp0 bigIp0 bigIp1 bigIp1 rightAdv rightAdv rightAdv"
        "bigIp2 bigIp2 bigIp3 bigIp3 rightAdv rightAdv rightAdv"
        "bigIp2 bigIp2 bigIp3 bigIp3 . . ."
        "bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv bottomAdv"
    }
  }
}
.irIQZt {
  margin: 0 auto;
  flex-grow: 1;
  .iRQTOz {
    display: grid;
    grid-template-rows: repeat(auto-fill,94px);
    grid-gap: 16px;
    grid-auto-flow: dense;
    justify-content: center;
    margin: 16px auto 0;
    padding: 0;
    list-style-type: none;
    grid-template-columns: repeat(var(--gridTemplateColumns),94px);
  }
  .iRQTOz::before{
    content: '';
    display: block;
  }
  .title{
    grid-area: ibx;
    display: flex;
    border-radius: 16px;
    justify-content: center;
    flex-direction: column;
    background: rgb(255, 255, 255);
    padding: 10px 16px;
    box-shadow: 0 6px 12px 0 rgba(0,0,0,.24);
    align-items: center;
    box-sizing: border-box;
    h1{
      margin: 0;
      font: 500 20px/24px Torus,sans-serif;
      color: #002b50;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .rightAdv, .bottomAdv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 16px;
    .iFmTCx {
      display: inline-flex;
      flex-direction: column;
      .eDhvMX {
        //background: rgba(255, 255, 255, 0.5);
      }

      .iZJgLq {
        font: 400 9px / 2 Arial, sans-serif;
        //text-transform: uppercase;
        text-align: center;
        opacity: 0.7;
        height: 20px;
        letter-spacing: 1px;
        color: #333333;
      }
    }
  }
  .rightAdv{
    height: 314px;
  }
  .bottomAdv{
    height: 94px;
  }
}
.content, .recommend{
  //overflow: hidden;
  margin-top: 16px;
  .app-item {
    width: 94px;
    height: 94px;
    display: block;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    border-radius: 16px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 16px;
      //background: white;
    }
  }
  .app-item::after {
    content: "";
    opacity: 0;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 4;
    transition: box-shadow .6s cubic-bezier(.25, .1, .25, 1),opacity .3s cubic-bezier(.25, .1, .25, 1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    border-radius: 16px;
    contain: strict;
  }
  .app-item:hover {
    transform: scale(1.04255) translate(0px, -4px);
    transition-duration: 0.3s;
  }
  .app-item:hover::after {
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 12px 0px;
  }
  .esaxGV {
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    padding: 6px;
    color: rgb(255, 255, 255);
    font: 700 var(--tileFontSize,12px)/1.3 'Proxima Nova',sans-serif;
    text-align: center;
    z-index: 6;
    transition: transform .3s cubic-bezier(.25, .1, .25, 1) .1s,opacity .3s cubic-bezier(.25, .1, .25, 1) .1s;
    transform: translate(0px, 8px);
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    pointer-events: none;
    word-break: break-all;
  }
  @media (hover: hover) {
    .app-item:hover {
      transform: scale(1.01869) translate(0px, -4px)!important;
    }
    .app-item:hover::after {
      opacity: 1;
      background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.3) 100%);
    }
    .app-item:hover .sc-963fcq-0 {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }
}
.recommend{
  margin-top: 32px;
  .app-item {
    width: 94px;
    height: 94px;
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    float: left;
    margin-right: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    border-radius: 16px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 16px;
      //background: white;
    }
  }
  .app-item::after {
    content: "";
    opacity: 0;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 4;
    transition: box-shadow .6s cubic-bezier(.25, .1, .25, 1),opacity .3s cubic-bezier(.25, .1, .25, 1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    border-radius: 16px;
    contain: strict;
  }
  .app-item:hover {
    transform: scale(1.04255) translate(0px, -4px);
    transition-duration: 0.3s;
  }
  .app-item:hover::after {
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 12px 0px;
  }
  .esaxGV {
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    padding: 6px;
    color: rgb(255, 255, 255);
    font: 700 var(--tileFontSize,12px)/1.3 'Proxima Nova',sans-serif;
    text-align: center;
    z-index: 6;
    transition: transform .3s cubic-bezier(.25, .1, .25, 1) .1s,opacity .3s cubic-bezier(.25, .1, .25, 1) .1s;
    transform: translate(0px, 8px);
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    pointer-events: none;
    word-break: break-all;
  }
  @media (hover: hover) {
    .app-item:hover {
      transform: scale(1.01869) translate(0px, -4px)!important;
    }
    .app-item:hover::after {
      opacity: 1;
      background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.3) 100%);
    }
    .app-item:hover .sc-963fcq-0 {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }
  @media (min-width: 1871px) {
    .app-item:nth-child(17n) {
      margin-right: 0;
    }
  }
  @media (min-width: 1541px) and (max-width: 1870.9px) {
    .app-item:nth-child(14n) {
      margin-right: 0;
    }
  }
  @media (min-width: 1321px) and (max-width: 1540.9px) {
    .app-item:nth-child(12n) {
      margin-right: 0;
    }
  }
  @media (min-width: 1211px) and (max-width: 1320.9px) {
    .app-item:nth-child(11n) {
      margin-right: 0;
    }
  }
  @media (min-width: 991px) and (max-width: 1210.9px) {
    .app-item:nth-child(9n) {
      margin-right: 0;
    }
  }
  @media (min-width: 111px) and (max-width: 990.9px) {
    .app-item:nth-child(7n) {
      margin-right: 0;
    }
  }
}
.content:after{
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.recommend:after{
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.segmentation{
  margin-top: 32px;
  font: 600 24px "Torus",sans-serif;
  color: #ffffff;
}
.type-list{
  margin: 0 auto 0;
  .bottom-text {
    position: relative;
    margin: 30px 0;
    padding: 18px 24px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
    h1{
      font: 500 24px Torus,sans-serif;
    }
    p{
      margin: 24px 0;
      font: 400 16px/24px Proxima Nova,Open Sans,Gill Sans MT,Gill Sans,Arial,sans-serif;
      color: #002b50;
    }
    a{
      color: #054a91;
      text-decoration: underline;
    }
    h3{
      color: #002b50;
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    h4{
      margin-top: 10px;
      color: #002b50;
    }
    ul,ol{
      padding-left: 40px;
      li{
        margin-bottom: 5px;
        color: #002b50;
      }
    }
    .about{
      color: #002b50;
      font-size: 9px;
      font-weight: 700;
      letter-spacing: 1px;
      margin: 3px 0 0;
      text-transform: uppercase;
      ul{
        list-style: none;
        margin: 0;
        overflow: hidden;
        padding: 0;
        text-overflow: ellipsis;
        li{
          display: inline;
          a{
            font-size: 9px;
            letter-spacing: 1px;
            text-transform: uppercase;
            white-space: nowrap;
            color: #002b50;
            text-decoration: none;
          }
        }
        li:nth-child(2)::before{
          content: "›";
          font-size: 13px;
          margin: 0 4px;
        }
      }
    }
    .title{
      font-size: 20px;
      color: #002b50;
      margin-top: 10px;
      h2{
        font: 500 20px Torus,sans-serif;
      }
    }
  }
}
@keyframes djqRmU{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes etkMLf{
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.dgmlwU{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: djqRmU .2s cubic-bezier(.25, .1, .25, 1) both;
  z-index: 100001;
}
.AFSJx{
  display: flex;
  align-items: center;
  justify-content: center;
  div{
    width: 18px;
    height: 18px;
    margin: 0 2px;
    border-radius: 100%;
    background: #FC8F47;
    animation: 1.4s ease-in-out 0s infinite normal both running etkMLf;
  }
  div:nth-child(2){
    animation-delay: -0.16s;
  }
}
</style>

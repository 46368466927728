<template>
  <div class="big" v-title :data-title="`Online Games on ${this.$headToUpperCase} ——Let's play`">
    <div v-if="contentType">
      <CrazyContent></CrazyContent>
<!--      <Content></Content>-->
<!--      <BottomNav portal="pc_home"></BottomNav>-->
      <div class="shortcut-popup-desktop" v-if="$store.state.deferredPromptType && $store.state.installType == 1">
        <div class="shortcut-popup-desktop__description">
          <img :src="gamepad" class="shortcut-popup-desktop__logo" alt="">
          <p class="shortcut-popup-desktop__text">Add a quick start icon for {{ this.$headToUpperCase}} !</p>
        </div>
        <div class="shortcut-popup-desktop__buttons">
          <el-button @click="After">Later</el-button>
          <el-button @click="addToDesktop">Add</el-button>
        </div>
      </div>
    </div>
    <div style="display: contents" v-if="smegmaType">
      <div class="AFSJx dgmlwU" style="background-color: #ffffff">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import Content from '@/components/HomeIndex/Content';
import CrazyContent from '@/components/HomeIndex/CrazyContent';
import BottomNav from '@/components/BottomNav';
import {determinePcOrMove, pageInitLog, pageOutLog, clickGameLog, recentGame} from '@/utils/utils.js'
import gamepad from '@/assets/gamepad.png'
export default {
  name: "HomeIndex",
  components: {
    Content,
    BottomNav,
    CrazyContent
  },
  data() {
    return {
      smegmaType: false,
      contentType: false,
      gamepad,
    }
  },
  created() {
    if (localStorage.getItem('installCancelTime')) {
      if (this.$dayjs(this.$dayjs().format('YYYY-MM-DD')).isAfter(this.$dayjs(localStorage.getItem('installCancelTime')))) {
        this.$store.commit("changeInstallType",'1')
        localStorage.setItem('installType','1')
        localStorage.setItem('installCancelTime',this.$dayjs().format('YYYY-MM-DD'))
      } else {
        this.$store.commit("changeInstallType",localStorage.getItem('installType') ? localStorage.getItem('installType') : '1')
      }
    } else {
      this.$store.commit("changeInstallType",localStorage.getItem('installType') ? localStorage.getItem('installType') : '1')
    }
  },
  mounted() {
    if (determinePcOrMove() == 1) {
      let { channel } = this.$route.query
      this.$router.push({
        path: '/M/homeIndex',
        query: {
          channel
        }
      },()=>{})
    } else {
      // 进入页面埋点
      pageInitLog('pc_home')
      this.smegmaType = true
      this.timer = setInterval(()=>{
        if (window.loadOk) {
          clearInterval(this.timer)
          this.smegmaType = false
          this.contentType = true
          document.title = `Online Games on ${this.$headToUpperCase} ——Let's play`
        }
      },2000)
    }
  },
  methods: {
    addToDesktop() {
      console.log(this.$store.state.deferredPrompt);
      this.$store.state.deferredPrompt && this.$store.state.deferredPrompt.prompt();
      this.$store.commit("changePWA",{deferredPrompt: null,deferredPromptType: this.$store.state.deferredPromptType})
      localStorage.setItem('installType','0')
      this.$store.commit("changeInstallType",'0')
      this.$store.commit("changeInstallPageClick",'1')
      localStorage.setItem('installCancelTime',this.$dayjs().format('YYYY-MM-DD'))
    },
    After() {
      localStorage.setItem('installType','0')
      this.$store.commit("changeInstallType",'0')
      localStorage.setItem('installCancelTime',this.$dayjs().format('YYYY-MM-DD'))
    }
  },
  // beforeRouteLeave(to, from, next){
  //   console.log(to);
  //   console.log(from);
  //   if (to.query.gameId) {
  //     getAllJson().map((item)=>{
  //       if (item.gameId == to.query.gameId) {
  //         clickGameLog('pc_home', item)
  //         recentGame(item)
  //         if (this.$originCopyWriting == 'ajoy') {
  //           bge && bge('event', 'button', {configId: '903985143796275456'});
  //           sessionStorage.setItem('pixelType','1')
  //         }
  //       }
  //     })
  //   }
  //   next()
  // },
  beforeDestroy() {
    // 离开页面埋点
    pageOutLog('pc_home')
  }
}
</script>

<style lang="less" scoped>
.big{
  @keyframes djqRmU{
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  @keyframes etkMLf{
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  .dgmlwU{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: djqRmU .2s cubic-bezier(.25, .1, .25, 1) both;
    z-index: 100001;
  }
  .AFSJx{
    display: flex;
    align-items: center;
    justify-content: center;
    div{
      width: 18px;
      height: 18px;
      margin: 0 2px;
      border-radius: 100%;
      background: #FC8F47;
      animation: 1.4s ease-in-out 0s infinite normal both running etkMLf;
    }
    div:nth-child(2){
      animation-delay: -0.16s;
    }
  }
  .shortcut-popup-desktop{
    background: linear-gradient(99.09deg, #3784ff 0%, #3a39ff 100%);
    border-radius: 18px;
    bottom: 24px;
    box-shadow: 0 16px 62px rgba(3,21,48,.15);
    color: #222222;
    padding: 20px;
    position: fixed;
    right: 24px;
    z-index: 997;
    .shortcut-popup-desktop__description{
      align-items: center;
      display: flex;
      font: 500 18px/1.2em;
      margin-bottom: 25px;
      margin-left: 98px;
      max-width: 233px;
      .shortcut-popup-desktop__logo{
        height: 112px;
        left: -30px;
        position: absolute;
        top: -29px;
        width: 132px;
      }
      .shortcut-popup-desktop__text{
        word-wrap: break-word;
        --light-cyan-100: #feffff;
        box-sizing: border-box;
        color: var(--light-cyan-100);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        margin: 0;
        max-width: 285px;
        padding-right: 0;
      }
    }
    .shortcut-popup-desktop__buttons{
      align-items: center;
      display: flex;
      justify-content: center;
      .el-button{
        width: 166px;
        background: #7594ff;
        border: none;
        color: #FFFFFF;
        font-size: 15px;
        font-weight: 600;
      }
      .el-button:nth-child(2){
        background: #FFFFFF;
        color: #222222;
      }
    }
  }
}
</style>

<template>
<div class="big_box">
  <header class="header" :style="{background: navBj}">
    <div class="box">
      <div class="left"><i class="el-icon-s-unfold" @click="menuClick"></i></div>
      <div class="middle"><a :href="'/M/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): '')" @click="logoClick"><img :src="logo" alt=""></a></div>
      <div class="right"><div></div><div><a :href="'/M/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): '')" @click="logoClick"><img :src="crazyHome" alt=""></a><i @click="searchClick" class="el-icon-search" style="margin-right: 15px;font-weight: 600;font-size: 28px"></i></div></div>
    </div>
    <div class="middle_content" v-if="searchType">
      <div class="input_border">
        <el-input
            placeholder="Search"
            prefix-icon="el-icon-search"
            @input="inputChange"
            @focus="inputFocus"
            @blur="inputBlur"
            :style="{'--inputBj': searchBj}"
            v-model="search">
        </el-input>
<!--        <div class="close_button" v-if="inputFocusType"><i class="el-icon-close"></i></div>-->
      </div>
      <div class="search_item" :style="{background: navBj}" v-if="screenList.length">
        <a v-for="(item,index) in screenList" :key="index" :href="'/M' + '/details/'+item.Name.replace(/\s+/g, '')+'?gameId='+item.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')" class="sc-wr3rvk-02 fcDjQV" @click="iconClick(item)">
          <img :src="item.iconUrl" class="dYqVqC" alt="">
          <div class="kZbSoa">
            <div class="hQIsLG">
              <div class="UVa-dc">{{item.Name}}</div>
            </div>
            <span class="eyfKaw"></span>
          </div>
        </a>
      </div>
    </div>
  </header>
  <div class="mongolian_layer" v-if="!menuType" @click="closeNav"></div>
  <nav class="nav" :style="menuType ? {left: '-210px',background: navBj} : {background: navBj}">
    <div class="close">
      <i class="el-icon-close" @click="closeNav"></i>
    </div>
    <div class="scrollbar_box">
      <a :href="'/M/homeIndex'+($route.query.channel ? ('?channel='+$route.query.channel): '')">
        <div class="game_type" :style="$route.path == '/M/homeIndex' ? {borderLeftColor: '#FC8F47'} : {borderLeftColor: 'transparent'}">
          <div class="img_box"><img :src="$route.path == '/M/homeIndex' ? homeSelected : home" alt=""></div>
          <div class="text" :style="$route.path == '/M/homeIndex' ? {color: '#FC8F47'} : {color: '#ffffff'}">Home</div>
        </div>
      </a>
      <a :href="`/M/gameType${$route.query.channel ? ('?channel='+$route.query.channel) : ''}${$route.query.channel ? '&gameType='+item.type : '?gameType='+item.type}`" v-for="(item,index) in typeList" :key="index">
        <div class="game_type" :style="$route.query.gameType == item.type ? {borderLeftColor: '#FC8F47'} : {borderLeftColor: 'transparent'}">
          <div class="img_box"><img :src="$route.query.gameType == item.type ? item.navSelectedIcon : item.navIcon" alt=""></div>
          <div class="text" :style="$route.query.gameType == item.type ? {color: '#FC8F47'} : {color: '#ffffff'}">{{ item.type }}</div>
        </div>
      </a>
      <hr class="horizontal_line">
      <div class="privacy">
        <a :href="`/privacy${$route.query.channel ? ('?channel='+$route.query.channel) : ''}`" target="_blank">
          <span>Privacy</span>
        </a>
      </div>
    </div>
    <div class="bottom_box">
      <a :href="`/contactUs${$route.query.channel ? ('?channel='+$route.query.channel) : ''}`" target="_blank">
        <div class="game_type">
          <div class="img_box"><img :src="home" alt=""></div>
          <div class="text">Contact us</div>
        </div>
      </a>
    </div>
  </nav>
  <main class="content" :style="searchType ? null : {marginTop: '52px'}">
    <div class="game_box" id="game_box">
      <div class="box_top">
        <div class="right_arrow">
          <div>
            <h2>HOTGAME</h2>
            <a :href="'/M/gameType'+'?gameType=HOTGAME'+($route.query.channel ? ('&channel='+$route.query.channel): '')">View more</a>
          </div>
          <i class="el-icon-d-arrow-right" @click="rightArrowClick(null)"></i></div>
        <div class="game_list_box" id="game_list_box">
          <div class="game_item" v-for="(item,index) in recommendList" :key="index" id="game_item">
            <a :href="'/M/details/'+aItem.Name.replace(/\s+/g, '') + '?gameId='+aItem.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')" v-for="(aItem,aIndex) in item" :key="aIndex" class="sc-wr3rvk-0" @click="iconClick(aItem)">
              <img :src="aItem.iconUrl" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="adv" style="grid-area: adv" v-if="!advPosition">
        <ins v-if="!noAdvertisements" class="adsbygoogle"
             id="homeAdv"
             style="display:block;min-height: 314px"
             :data-ad-client="caPub"
             :data-ad-slot="slot"
             data-ad-format="true"
             data-full-width-responsive="true"></ins>
      </div>
      <div v-for="(item,index) in typeList" :key="index">
        <div class="type_games">
          <div class="type_title">
            <div class="type_title_left">
              <h2>{{item.type}}</h2>
              <a :href="'/M/gameType'+'?gameType='+item.type+($route.query.channel ? ('&channel='+$route.query.channel): '')">View more</a>
            </div>
            <div class="right_arrow" @click="rightArrowClick(item.type)"><i class="el-icon-d-arrow-right"></i></div>
          </div>
          <div class="type_game_box">
            <div class="type_game_list" :id="`game_list_box${item.type}`">
              <div class="game_item" v-for="(aItem,aIndex) in item.gameList" :key="aIndex" :id="`game_item${item.type}`">
                <a :href="'/M/details/'+aItem.Name.replace(/\s+/g, '') + '?gameId='+aItem.gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')" class="sc-wr3rvk-0" @click="iconClick(aItem)">
                  <div class="game_name">{{aItem.Name}}</div>
                  <img v-lazy="aItem.iconUrl" alt="">
                </a>
              </div>
            </div>
          </div>
          <div v-if="index == 5" class="insert_box">
            <div class="insert_left">
              <div class="text">
                <h2>Play the Newest Games Instantly</h2>
                <p> {{ $headToUpperCase && $headToUpperCase}} features the latest and best free online games. You can enjoy playing fun games without interruptions from downloads, intrusive ads, or pop-ups. Just load up your favorite games instantly in your web browser and enjoy the experience.</p>
                <div class="learn_more" @click="learnMoreClick">learn more</div>
              </div>
            </div>
            <div class="insert_right">
              <div class="insert_game_box">
                <div class="insert_game_list" id="insert_list_box">
                  <div class="game_item" v-for="(item,index) in insertTypeList" :key="index" id="insert_game_item">
                    <a :href="'/M/gameType'+'?gameType='+aItem.type+($route.query.channel ? ('&channel='+$route.query.channel): '')" v-for="(aItem,aIndex) in item" :key="aIndex">
                      <div class="item_type">
                        <img :src="aItem.navIcon" alt="">
                        <div class="type_name">{{aItem.type}}</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="adv" style="grid-area: adv" v-if="index == 1 && advPosition">
          <ins v-if="!noAdvertisements" class="adsbygoogle"
               id="homeAdv"
               style="display:block;min-height: 314px"
               :data-ad-client="caPub"
               :data-ad-slot="slot"
               data-ad-format="true"
               data-full-width-responsive="true"></ins>
        </div>
      </div>
    </div>
    <div class="end"><img :src="logo" alt=""></div>
    <div class="bottom-box">
      <div class="bottom-text">
        <div class="about">About {{$headToUpperCase && $headToUpperCase}}</div>
        <div class="title"><h1>Free Online Games</h1></div>
        <p>{{$headToUpperCase && $headToUpperCase}} has the best free online games selection and offers the most fun experience to play alone or with friends. We offer instant play to all our games without downloads, login, popups or other distractions. Our games are playable on desktop, tablet and mobile so you can enjoy them at home or on the road. Every month over 50 million gamers from all over the world play their favorite games on {{$headToUpperCase && $headToUpperCase}}.</p>
        <h3>Our game selection</h3>
        <p>Game developers release fun New Games on our platform on a daily basis. Our most Popular Games include hits like
          <a :href="'/M/details/'+(allGames && allGames[0] && allGames[0].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[0] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(allGames && allGames[0])">{{allGames && allGames[0] && allGames[0].Name}}</a>,
          <a :href="'/M/details/'+(allGames && allGames[1] && allGames[1].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[1] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(allGames && allGames[1])">{{allGames && allGames[1] && allGames[1].Name}}</a>,
          <a :href="'/M/details/'+(allGames && allGames[2] && allGames[2].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[2] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(allGames && allGames[2])">{{allGames && allGames[2] && allGames[2].Name}}</a>
          and
          <a :href="'/M/details/'+(allGames && allGames[3] && allGames[3].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[3] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(allGames && allGames[3])">{{allGames && allGames[3] && allGames[3].Name}}</a>.
          These games are only playable on {{$headToUpperCase && $headToUpperCase}}. We also have online classics like
          <a :href="'/M/details/'+(allGames && allGames[4] && allGames[4].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[4] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(allGames && allGames[4])">{{allGames && allGames[4] && allGames[4].Name}}</a>,
          <a :href="'/M/details/'+(allGames && allGames[5] && allGames[5].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[5] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(allGames && allGames[5])">{{allGames && allGames[5] && allGames[5].Name}}</a>,
          <a :href="'/M/details/'+(allGames && allGames[6] && allGames[6].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[6] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(allGames && allGames[6])">{{allGames && allGames[6] && allGames[6].Name}}</a>,
          <a :href="'/M/details/'+(allGames && allGames[7] && allGames[7].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[7] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(allGames && allGames[7])">{{allGames && allGames[7] && allGames[7].Name}}</a>,
          <a :href="'/M/details/'+(allGames && allGames[8] && allGames[8].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[8] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(allGames && allGames[8])">{{allGames && allGames[8] && allGames[8].Name}}</a>,
          <a :href="'/M/details/'+(allGames && allGames[9] && allGames[8].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[9] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(allGames && allGames[9])">{{allGames && allGames[9] && allGames[9].Name}}</a>
          and
          <a :href="'/M/details/'+(allGames && allGames[10] && allGames[10].Name.replace(/\s+/g, '')) + '?gameId='+(allGames && allGames[10] && allGames[0].gameId)+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="iconClick(allGames && allGames[10])">{{allGames && allGames[10] && allGames[10].Name}}</a> to play for free.
        </p>
        <h3>Start playing</h3>
        <p>Unsure what game to play? Start your game discovery on our homepage or pick a game from any of these popular categories:</p>
        <ul>
          <li v-for="(item,index) in typeList.slice(0,6)" :key="index">
            <a :href="'/M/gameType'+'?gameType='+item.type+($route.query.channel ? ('&channel='+$route.query.channel): '')" @click="classClick(item.type)">
              {{item.type.slice(0,1).toUpperCase() + item.type.slice(1).toLowerCase()}} Games
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="topping" v-if="toppingType" :style="$store.state.deferredPromptType && $store.state.installType == 1 ? {bottom: '183px'} : {bottom: '20px'}">
      <div @click="toppingClick">
        <i class="el-icon-top"></i>
      </div>
    </div>
  </main>
  <el-dialog
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose">
    <div class="dialog_box">
      <h2>Play the Newest Games Instantly</h2>
      <p>{{ $headToUpperCase && $headToUpperCase}} features the latest and best free online games. You can enjoy playing fun games without interruptions from downloads, intrusive ads, or pop-ups. Just load up your favorite games instantly in your web browser and enjoy the experience.</p>
      <p>You can play our games on desktop mobile devices. That includes everything from desktop PCs, laptops, and Chromebooks, to the latest smartphones and tablets from Apple and Android.</p>
    </div>
  </el-dialog>
</div>
</template>

<script>
import logo from '/public/img/logos/defaultLogo.png'
import newLogo from '@/assets/newLogo.png'
import rtsplayLogo from '@/assets/rtsplayLogo.png'
import crazyHome from '@/assets/crazyHome.png'
import home from '@/assets/nav/home.png'
import homeSelected from '@/assets/nav/homeSelected.png'
import {getGameTypeList, clickClassificationLog, iconClickEscalation, clickGameLog, Observer, recentGame, pageOutLog, shuffle} from "@/utils/utils";
export default {
  name: "CrazyAppList",
  data() {
    return {
      logo: this.$originCopyWriting == 'mami' ? logo : this.$originCopyWriting == 'rtsplay' ? rtsplayLogo : newLogo,
      home,
      homeSelected,
      crazyHome,
      search: '',
      screenList: [],
      menuType: true,
      toppingType: false,
      insertTypeList: [],
      insertRightArrow: true,
      recommendList: [],
      dialogVisible: false,
      inputFocusType: false,
      allGames: [], // 全部游戏
      typeList: [], // 游戏类型
      homeAdv: false, // 是否展示广告位置
      noAdvertisements: null, // 1没广告
      navBj: '',
      searchBj: '',
      searchType: false,
      caPub: null,
      slot: null,
      advPosition: null
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { m_home_slot, noAdvertisements, channel_ca_pub, navBj, searchBj, advPosition } = channelInfo || {}
    this.slot = m_home_slot
    this.caPub = channel_ca_pub
    this.noAdvertisements = noAdvertisements
    this.navBj = navBj
    this.searchBj = searchBj
    this.advPosition = advPosition
  },
  mounted() {
    // 获取需要曝光的item
    setTimeout(()=>{
      let itemArr = [...document.getElementsByClassName("sc-wr3rvk-0")]
      itemArr && Array.from(itemArr).map((item)=>{
        Observer('mobile_home').observe(item)
      })
    })
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { noAdvertisements } = channelInfo || {}
    this.typeList = getGameTypeList() || []
    let insertTypeList = getGameTypeList() || []
    let newInsertTypeList = []
    let num = Math.ceil(insertTypeList.length / 2)
    for (let i = 1; i <= num; i++) {
      newInsertTypeList.push(insertTypeList.splice(0,2))
    }
    this.insertTypeList = newInsertTypeList
    this.getJson()
    if (!noAdvertisements) {
      setTimeout(()=>{
        window.addAds()
      },800)
    }
    let innerHtml = document.getElementById('homeAdv') && document.getElementById('homeAdv').innerHTML
    if (innerHtml) {
      this.homeAdv = true
    } else {
      this.homeAdv = false
    }
    window.onresize = () => {
      this.getMoveData()
    }
    this.getMoveData()
    let toppingType = false
    window.addEventListener('scroll',()=> {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let status = false
      setTimeout(()=>{
        status = true
        if (scrollTop >= 900 && status) {
          toppingType = true
        } else {
          toppingType = false
        }
        this.toppingType = toppingType
      },1000)
      if (scrollTop >= 900 && status) {
        toppingType = true
      } else {
        toppingType = false
      }
      this.toppingType = toppingType
    })
  },
  methods: {
    searchClick() {
      this.searchType = !this.searchType
    },
    closeNav() {
      this.menuType = true
    },
    getJson() {
      document.documentElement.scrollTop = 0
      let bigImg = []
      let newArr = []
      let SHOOTINGArr= []
      let CARArr= []
      let BALLArr= []
      let GIRLSArr= []
      let CASUALArr= []
      let PUZZLEArr= []
      let ACTIONArr= []
      let RACINGArr= []
      let PARKOURArr= []
      let DArr= []
      let ADVENTUREArr= []
      let ARCADEArr= []
      let SPORTSArr= []
      let arr = getAllJson()
      arr && arr.map((item)=>{
        if (item.SHOOTING == 1) {
          SHOOTINGArr.push(item)
          this.$set(this.typeList[0],'gameList',shuffle(SHOOTINGArr))
          this.$set(this.typeList[0],'leftArrow',false)
          this.$set(this.typeList[0],'rightArrow',true)
        }
        if (item.CAR == 1) {
          CARArr.push(item)
          this.$set(this.typeList[1],'gameList',shuffle(CARArr))
          this.$set(this.typeList[1],'leftArrow',false)
          this.$set(this.typeList[1],'rightArrow',true)
        }
        if (item.BALL == 1) {
          BALLArr.push(item)
          this.$set(this.typeList[2],'gameList',shuffle(BALLArr))
          this.$set(this.typeList[2],'leftArrow',false)
          this.$set(this.typeList[2],'rightArrow',true)
        }
        if (item.GIRLS == 1) {
          GIRLSArr.push(item)
          this.$set(this.typeList[3],'gameList',shuffle(GIRLSArr))
          this.$set(this.typeList[3],'leftArrow',false)
          this.$set(this.typeList[3],'rightArrow',true)
        }
        if (item.CASUAL == 1) {
          CASUALArr.push(item)
          this.$set(this.typeList[4],'gameList',shuffle(CASUALArr))
          this.$set(this.typeList[4],'leftArrow',false)
          this.$set(this.typeList[4],'rightArrow',true)
        }
        if (item.PUZZLE == 1) {
          PUZZLEArr.push(item)
          this.$set(this.typeList[5],'gameList',shuffle(PUZZLEArr))
          this.$set(this.typeList[5],'leftArrow',false)
          this.$set(this.typeList[5],'rightArrow',true)
        }
        if (item.ACTION == 1) {
          ACTIONArr.push(item)
          this.$set(this.typeList[6],'gameList',shuffle(ACTIONArr))
          this.$set(this.typeList[6],'leftArrow',false)
          this.$set(this.typeList[6],'rightArrow',true)
        }
        if (item.RACING == 1) {
          RACINGArr.push(item)
          this.$set(this.typeList[7],'gameList',shuffle(RACINGArr))
          this.$set(this.typeList[7],'leftArrow',false)
          this.$set(this.typeList[7],'rightArrow',true)
        }
        if (item.PARKOUR == 1) {
          PARKOURArr.push(item)
          this.$set(this.typeList[8],'gameList',shuffle(PARKOURArr))
          this.$set(this.typeList[8],'leftArrow',false)
          this.$set(this.typeList[8],'rightArrow',true)
        }
        if (item['3D'] == 1) {
          DArr.push(item)
          this.$set(this.typeList[9],'gameList',shuffle(DArr))
          this.$set(this.typeList[9],'leftArrow',false)
          this.$set(this.typeList[9],'rightArrow',true)
        }
        if (item.ADVENTURE == 1) {
          ADVENTUREArr.push(item)
          this.$set(this.typeList[10],'gameList',shuffle(ADVENTUREArr))
          this.$set(this.typeList[10],'leftArrow',false)
          this.$set(this.typeList[10],'rightArrow',true)
        }
        if (item.ARCADE == 1) {
          ARCADEArr.push(item)
          this.$set(this.typeList[11],'gameList',shuffle(ARCADEArr))
          this.$set(this.typeList[11],'leftArrow',false)
          this.$set(this.typeList[11],'rightArrow',true)
        }
        if (item.SPORTS == 1) {
          SPORTSArr.push(item)
          this.$set(this.typeList[12],'gameList',shuffle(SPORTSArr))
          this.$set(this.typeList[12],'leftArrow',false)
          this.$set(this.typeList[12],'rightArrow',true)
        }
        if (item.ImgSize == 1) {
          bigImg.push(item)
        } else {
          newArr.push(item)
        }
      })
      bigImg.map((item)=>{
        newArr.map((items,index)=>{
          if (index % 5 == 0 && items.ImgSize != 1 && (newArr.findIndex((it)=>it.gameId == item.gameId) == -1)) {
            newArr.splice(index,0,item)
          }
        })
      })
      let list = newArr.slice(0,30)
      let num = Math.ceil(list.length / 2)
      for (let i = 1; i <= num; i++) {
        list.push(list.splice(0,2))
      }
      this.recommendList = list
      this.allGames = arr
    },
    menuClick() {
      this.menuType = false
      this.search = ''
      this.screenList = []
    },
    logoClick() {
      this.search = ''
      this.screenList = []
    },
    // 搜索框
    inputChange() {
      let value = this.search
      let inputValue = value.toLowerCase()
      if (inputValue.trim()) {
        let arr = []
        let allJson = getAllJson()
        allJson && allJson.map((item)=>{
          if (item[value.toUpperCase()] == 1) {
            let index = arr.findIndex((items)=>items.gameId == item.gameId)
            if (index == -1) {
              arr.push(item)
            }
          }
          if (item.Name.toLowerCase().includes(`${inputValue}`)) {
            let index = arr.findIndex((items)=>items.gameId == item.gameId)
            if (index == -1) {
              arr.push(item)
            }
          }
        })
        this.screenList = arr
      } else {
        this.screenList = []
      }
      // this.exposure()
    },
    inputFocus() {
      this.inputFocusType = true
    },
    inputBlur() {
      setTimeout(()=>{
        this.inputFocusType = false
        this.search = ''
        this.screenList = []
      },500)
    },
    getMoveData() {
      setTimeout(()=>{
        let gameBoxWidth = document.getElementById('game_box').offsetWidth - 16 - 8
        this.gameBoxWidth = gameBoxWidth
      })
    },
    rightArrowClick(type) {
      // 获取滚动条滚动数据
      let gameListBoxLeft = document.getElementById(`game_list_box${type ? type : ''}`).scrollLeft
      // 获取小数点后的第一位（shifting）如果小于6的话向左偏移减去小数乘以一个元素的宽度（shiftingValue）大于等于6加上剩余小数乘以一个元素的宽度（shiftingValue1）
      let shifting = Math.floor((gameListBoxLeft/98) * 10) % 10
      let shiftingValue = (gameListBoxLeft / 98 - Math.floor(gameListBoxLeft/98)) * 98
      let shiftingValue1 = (1 - (gameListBoxLeft / 98 - Math.floor(gameListBoxLeft/98))) * 98
      let gameItemWidth = document.getElementById(`game_item${type ? type : ''}`).offsetWidth
      document.getElementById(`game_list_box${type ? type : ''}`).scrollLeft += Math.trunc(this.gameBoxWidth / (gameItemWidth + 4)) * gameItemWidth + (Math.trunc(this.gameBoxWidth / (gameItemWidth + 4)) - 1 == 0 ? 1 : Math.trunc(this.gameBoxWidth / (gameItemWidth + 4)) - 1) * 4 + 4 + (shifting < 6 ? -shiftingValue : +shiftingValue1)
    },
    learnMoreClick() {
      this.dialogVisible = true
    },
    toppingClick() {
      document.documentElement.scrollTop = 0
    },
    handleClose() {
      this.dialogVisible = false
    },
    classClick(type) {
      clickClassificationLog('mobile_home',type)
    },
    iconClick(item) {
      clickGameLog('mobile_home', item)
      recentGame(item)
      // 离开页面埋点
      pageOutLog('mobile_home')
      iconClickEscalation()
    }
  }
}
</script>

<style lang="less" scoped>
.big_box{
  a {
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #fff;
  }
  /deep/.el-dialog{
    overflow-y: auto;
    color: rgb(102, 106, 127);
    border-radius: 20px;
    max-width: 600px;
    background-color: rgb(33, 34, 51);
    padding-top: 48px;
    padding-left: 24px;
    position: relative;
    .el-dialog__header{
      padding: 0;
    }
    .el-dialog__body{
      padding: 0;
    }
    .dialog_box{
      color: rgb(189, 191, 206);
      font-size: 14px;
      height: 500px;
      overflow: scroll;
      padding-right: 24px;
      h2{
        font-size: 20px;
      }
      p{
        margin: 1em 0;
      }
    }
    .dialog_box::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background: rgba(0, 0, 0, 0);
    }
    .dialog_box::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }
    .dialog_box::-webkit-scrollbar-thumb {
      background: rgb(170, 173, 190);
      border-radius: 30px;
    }
  }
  .mongolian_layer{
    top: 0;
    width: 100%;
    height: 100vh;
    background: #0C0D14;
    opacity: 0.5;
    position: fixed;
    z-index: 98;
  }
  .header{
    z-index: 11;
    position: fixed;
    background: #0645A1;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    width: 100%;
    top: 0;
    left: auto;
    right: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: flex-start;
    align-content: flex-start;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex-flow: wrap;
    -webkit-flex-flow: wrap;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    padding-top: 4px;
    border-bottom: 1px solid #1A1B28;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    box-sizing: border-box;
    .box{
      display: flex;
      justify-content: space-between;
      .left{
        width: 48px;
        height: 48px;
        color: #EFF0F7;
        font-size: 36px;
        display: flex;
        align-items: center;
        flex: 1;
        .el-icon-s-unfold{
          margin-left: 10px;
        }
      }
      .middle{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          height: 38px;
        }
      }
      .right{
        flex: 1;
        display: flex;
        align-items: center;
        color: white;
        justify-content: space-between;
        img{
          width: 32px;
          height: 24px;
          margin-right: 15px;
        }
      }
    }
    .middle_content{
      padding: 0 13px;
      margin-bottom: 9px;
      .input_border{
        border-color: transparent;
        border-style: solid;
        border-width: 1px;
        border-radius: 30px;
        position: relative;
        .close_button{
          border-radius: 30px;
          transition: all 250ms ease 0s;
          border: none;
          cursor: pointer;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          font-family: Nunito;
          font-weight: 800;
          font-size: 16px;
          box-sizing: border-box;
          padding: 0px;
          background: #4D64DE;
          color: rgb(249, 250, 255);
          height: 24px;
          width: 24px;
          position: absolute;
          right: 12px;
          top: 5px;
        }
      }
      .search_item{
        position: absolute;
        height: calc(100vh - 97px);
        background: #0E50A3;
        border-radius: 5px;
        width: 100%;
        left: 0;
        top: 97px;
        overflow-y: auto;
        .fcDjQV {
          font-size: 18px;
          padding: 16px;
          display: flex;
          .dYqVqC {
            border-radius: 8px;
            box-shadow: var(--boxShadow,0 4px 8px 0 rgba(0,0,0,.24));
            background: #bac9de;
            margin: 0px 16px 0px 0px;
            width: 64px;
            height: 64px;
          }
          .kZbSoa {
            margin: auto 0px;
          }
          .UVa-dc {
            margin: 0px;
            font: 500 20px/24px Torus, sans-serif;
            padding: 0px 10px 0px 0px;
            color: #ffffff;
          }
        }
        .fcDjQV:hover{
          background: #1B81D5;
        }
      }
      .search_item::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background: rgba(0, 0, 0, 0);
      }
      .search_item::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
      }
      .search_item::-webkit-scrollbar-thumb {
        background: rgb(170, 173, 190);
        border-radius: 30px;
      }
    }
    /deep/ .el-input__inner{
      border-radius: 30px;
      fill: rgb(170, 173, 190);
      background-color: var(--inputBj);
      border-color: transparent;
      border-style: solid;
      //border-width: 1px;
      color: rgb(249, 250, 255);
      font-family: Torus, sans-serif;
      height: 34px;
      line-height: 34px;
      padding-right: 40px;
    }
    /deep/ .el-input__inner::placeholder{
      color: rgba(255,255,255,0.8);
    }
    /deep/ .el-input__prefix{
      color: #FFFFFF;
    }
    /deep/ .el-input__icon{
      line-height: 34px;
      font-size: 20px;
    }
  }
  .nav{
    transition: visibility 0s,all 0.15s ease-in-out;
    height: 100vh;
    background: #4D64DE;
    content-visibility: auto;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    border-right: 1px solid #28293D;
    .close{
      border-radius: 30px;
      -webkit-transition: all 250ms ease;
      transition: all 250ms ease;
      border: none;
      cursor: pointer;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: Nunito;
      font-weight: 800;
      box-sizing: border-box;
      background: transparent;
      color: #EFF0F7;
      height: 48px;
      width: 48px;
      font-size: 32px;
      padding: 10px 0 0 10px;
    }
    .scrollbar_box::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .scrollbar_box, .bottom_box{
      width: 100%;
      height: calc(100% - 46px);
      display: flex;
      padding-top: 16px;
      padding-bottom: 30px;
      flex-direction: column;
      overflow: auto;
      overflow-x: hidden;
      overflow-anchor: none;
      .game_type{
        display: flex;
        flex-wrap: nowrap;
        width: 200px;
        height: 34px;
        -webkit-box-align: center;
        align-items: center;
        border-left-width: 6px;
        border-left-style: solid;
        border-left-color: transparent;
        .img_box{
          width: 60px;
          height: 34px;
          padding: 0 19px;
          text-align: center;
          margin-left: -6px;
          display: flex;
          -webkit-box-pack: center;
          -webkit-box-align: center;
          align-items: center;
          color: #878A9E;
          box-sizing: border-box;
          img{
            width: 18.33px;
            height: 18.33px;
          }
        }
        .text{
          transition: visibility 0s,opacity 0.3s,all 0.2s ease-in-out;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 120px;
          width: 100%;
          font: 500 16px/16px Torus, sans-serif;
        }
        .text:hover{
          transform: translate(8px, 0);
        }
      }
      .game_type:hover{
        cursor: pointer;
        color: rgb(204, 204, 204);
      }
      .horizontal_line{
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        border-width: 0;
        border-style: solid;
        border-color: rgba(255, 255, 255, 0.12);
        border-bottom-width: thin;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 16px;
        margin-right: 16px;
      }
      .privacy{
        margin-left: 20px;
        margin-top: 8px;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        span{
          color: #ffffff;
          -webkit-text-decoration: none;
          text-decoration: none;
          cursor: pointer;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    .scrollbar_box:hover::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      background: rgba(0,0,0,0);
    }
    .scrollbar_box:hover::-webkit-scrollbar-thumb {
      background: #AAADBE;
      border-radius: 30px;
    }
    .bottom_box{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 46px;
      background: rgba(26, 27, 40, 0.6);
      box-shadow: 0px -1px 0px #28293D;
      display: flex;
      padding-bottom: 0;
    }
  }
  .nav:hover{
    width: 200px;
    .privacy,.horizontal_line{
      display: flex;
    }
  }
  .content{
    min-height: calc(100vh - 98px - 8px);
    margin-top: 98px;
    .game_box{
      padding-left: 8px;
      padding-bottom: 20px;
      .box_top{
        overflow: hidden;
        position: relative;
        padding: 12px 8px;
        div{
          display: flex;
        }
        a{
          -webkit-align-self: center;
          -ms-flex-item-align: center;
          align-self: center;
          padding: 0;
          z-index: 2;
          text-transform: none;
          line-height: 1!important;
          -webkit-text-decoration: none;
          text-decoration: none;
          color: #A48EFF;
          font-weight: 700;
          font-size: 12px;
        }
        .right_arrow{
          color: #FFFFFF;
          /*text-align: right;*/
          padding: 10px 0;
          font-size: 20px;
          display: flex;
          justify-content: space-between;
          h2{
            -webkit-align-self: flex-end;
            -ms-flex-item-align: flex-end;
            align-self: flex-end;
            padding-right: 16px;
            font-size: 14px;
          }
        }
        .game_list_box{
          margin-block-end: 0;
          overflow: hidden;
          overflow-x: scroll;
          width: 100%;
          white-space: nowrap;
          list-style: none;
          -webkit-transform: translateZ(0);
          -moz-transform: translateZ(0);
          -ms-transform: translateZ(0);
          transform: translateZ(0);
          scroll-padding: 50px 50px 50px 50px;
          -ms-overflow-style: none;
          scrollbar-width: none;
          margin: 0;
          scroll-behavior: smooth;
          .game_item{
            display: inline-block;
            box-sizing: border-box;
            vertical-align: middle;
            position: relative;
            margin-left: 4px;
            a {
              border-radius: calc(8px + 2px);
              border-width: 2px;
              border-style: solid;
              border-color: transparent;
              box-sizing: border-box;
              display: block;
              contain: layout;
              z-index: 0;
              -webkit-transition: -webkit-transform .1s cubic-bezier(.5, 0, .1, 1);
              transition: transform .1s cubic-bezier(.5, 0, .1, 1);
              transition-delay: 0s;
              background-color: rgba(255, 255, 255, 0.07);
              -webkit-background-clip: padding-box;
              background-clip: padding-box;
              position: relative;
              margin-left: 0;
              margin-top: 0;
              width: 94px;
              height: 94px;

              .game_name {
                color: #FFFFFF;
                position: absolute;
                bottom: 5px;
                margin-bottom: 8px;
                margin-left: 8px;
                z-index: 5;
                font-size: 11.2px;
                font-weight: 700;
                width: 0;
                height: 0;
                overflow: hidden;
              }

              img {
                -webkit-transition: opacity .3s cubic-bezier(.5, 0, .1, 1);
                transition: opacity .3s cubic-bezier(.5, 0, .1, 1);
                transition-delay: 0ms;
                z-index: 1;
                border-radius: 8px;
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                color: transparent;
              }
            }
          }
          .game_item:nth-child(1){
            margin-left: 0;
          }
        }
        .game_list_box::-webkit-scrollbar {
          display: none;
        }
      }
      .type_games{
        content-visibility: auto;
        contain-intrinsic-size: auto 171px;
        contain: layout paint;
        padding-left: 0;
        overflow: hidden;
        margin-top: 4px;
        position: relative;
        .type_title{
          padding-left: 8px;
          padding-right: 8px;
          min-height: 32px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          justify-content: space-between;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          color: #FFFFFF;
          line-height: 32px;
          //font-family: Torus, sans-serif;
          .type_title_left{
            display: flex;
          }
          .right_arrow{
            font-size: 20px;
          }
          h2{
            -webkit-align-self: flex-end;
            -ms-flex-item-align: flex-end;
            align-self: flex-end;
            padding-right: 16px;
            font-size: 14px;
          }
          a{
            -webkit-align-self: center;
            -ms-flex-item-align: center;
            align-self: center;
            padding: 0;
            z-index: 2;
            text-transform: none;
            line-height: 1!important;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: #A48EFF;
            font-weight: 700;
            font-size: 12px;
          }
        }
        .type_game_box{
          position: relative;
          overflow-y: hidden;
          z-index: 2;
          padding: 12px 8px;
          .type_game_list{
            margin-block-end: 0;
            overflow: hidden;
            overflow-x: scroll;
            width: 100%;
            white-space: nowrap;
            list-style: none;
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            scroll-padding: 50px 50px 50px 50px;
            -ms-overflow-style: none;
            scrollbar-width: none;
            margin: 0;
            scroll-behavior: smooth;
            .game_item{
              display: inline-block;
              box-sizing: border-box;
              vertical-align: middle;
              position: relative;
              width: 94px;
              height: 94px;
              margin-left: 4px;
              a{
                border-radius: calc(8px + 2px);
                border-width: 2px;
                border-style: solid;
                border-color: transparent;
                box-sizing: border-box;
                display: block;
                contain: layout;
                z-index: 0;
                -webkit-transition: -webkit-transform .1s cubic-bezier(.5, 0, .1, 1);
                transition: transform .1s cubic-bezier(.5, 0, .1, 1);
                transition-delay: 0s;
                background-color: rgba(255,255,255,0.07);
                -webkit-background-clip: padding-box;
                background-clip: padding-box;
                position: relative;
                margin-left: 0;
                margin-top: 0;
                width: 100%;
                height: 100%;
                .game_name{
                  color: #FFFFFF;
                  position: absolute;
                  bottom: 5px;
                  margin-bottom: 8px;
                  margin-left: 8px;
                  z-index: 5;
                  font-size: 11.2px;
                  font-weight: 700;
                  width: 0;
                  height: 0;
                  overflow: hidden;
                }
                img{
                  -webkit-transition: opacity .3s cubic-bezier(.5, 0, .1, 1);
                  transition: opacity .3s cubic-bezier(.5, 0, .1, 1);
                  transition-delay: 0ms;
                  z-index: 1;
                  border-radius: 8px;
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  height: 100%;
                  color: transparent;
                }
              }
              a::after {
                content: "";
                opacity: 0;
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 100%;
                height: 100%;
                background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.3) 100%);
                z-index: 4;
                transition: box-shadow .6s cubic-bezier(.25, .1, .25, 1), opacity .3s cubic-bezier(.25, .1, .25, 1);
                box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
                border-radius: 16px;
                contain: strict;
              }
              a:hover::after{
                opacity: 1;
                background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.3) 100%);
              }
              a:hover{
                .game_name{
                  width: 100%;
                  height: auto;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
            .game_item:nth-child(1){
              margin-left: 0;
            }
            .game_item:hover{
              .game_name{
                width: 100%;
                height: auto;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          .type_game_list::-webkit-scrollbar {
            display: none;
          }
        }
        .insert_box{
          margin: 16px 16px 24px 10px;
          content-visibility: auto;
          contain: layout paint;
          overflow-anchor: none;
          overflow: hidden;
          .insert_left{
            border-radius: 10px;
            background-color: #212233;
            position: relative;
            padding: 20px;
            box-sizing: border-box;
            .text{
              color: #BDBFCE;
              font-size: 14px;
              overflow: hidden;
              h2{
                font-size: 18px;
              }
              p{
                margin: 1em 0;
              }
              .learn_more{
                border-radius: 30px;
                -webkit-transition: all 250ms ease;
                transition: all 250ms ease;
                border: none;
                cursor: pointer;
                font-weight: 800;
                font-size: 16px;
                box-sizing: border-box;
                background: transparent;
                color: #A48EFF;
                height: 40px;
              }
            }
          }
          .insert_right{
            overflow: hidden;
            margin-top: 8px;
            .insert_game_box{
              position: relative;
              overflow-y: hidden;
              z-index: 2;
              .insert_game_list{
                margin-block-end: 0;
                overflow: hidden;
                overflow-x: scroll;
                width: 100%;
                white-space: nowrap;
                list-style: none;
                -webkit-transform: translateZ(0);
                -moz-transform: translateZ(0);
                -ms-transform: translateZ(0);
                transform: translateZ(0);
                scroll-padding: 50px 50px 50px 50px;
                -ms-overflow-style: none;
                scrollbar-width: none;
                margin: 0;
                scroll-behavior: smooth;
                .game_item{
                  display: inline-block;
                  box-sizing: border-box;
                  vertical-align: middle;
                  position: relative;
                  width: 173px;
                  height: 216px;
                  margin-left: 4px;
                  .item_type{
                    height: 102px;
                    background-color: #1A1B28;
                    margin-right: 8px;
                    padding: 16px;
                    border-radius: 10px;
                    margin-bottom: 12px;
                    box-sizing: border-box;
                    img{
                      width: 35px;
                      height: 35px;
                    }
                    .type_name{
                      color: #F9FAFF;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                      font: 500 20px/24px Torus, sans-serif;
                    }
                  }
                }
                .game_item:nth-child(1){
                  margin-left: 0;
                }
              }
              .insert_game_list::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
      }
    }
    .end{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100px;
      img{
        display: inline-block;
        fill: currentcolor;
        //width: 100px;
        height: 38px;
      }
    }
    .bottom-box{
      padding: 10px 20px;
    }
    .bottom-text {
      position: relative;
      margin: 30px 0 0;
      padding: 18px 24px;
      background: rgb(255, 255, 255);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 12px 0px;
      h1{
        font: 500 24px Torus,sans-serif;
      }
      p{
        margin: 24px 0;
        font: 400 16px/24px Proxima Nova,Open Sans,Gill Sans MT,Gill Sans,Arial,sans-serif;
        color: #002b50;
      }
      a{
        color: #054a91;
        text-decoration: underline;
      }
      h3{
        color: #002b50;
        display: block;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }
      ul{
        padding-left: 40px;
        li{
          margin-bottom: 5px;
          font-size: 16px;
        }
      }
      .about{
        color: #5d6b84;
        font-size: 9px;
        font-weight: 700;
        letter-spacing: 1px;
        margin: 3px 0 0;
        text-transform: uppercase;
      }
      .title{
        font-size: 20px;
        color: #002b50;
      }
    }
    .topping{
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 10;
      transition: opacity 0.2s ease 0s;
      opacity: 1;
      div{
        border-radius: 30px;
        transition: all 250ms ease 0s;
        border: none;
        cursor: pointer;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-family: Nunito;
        font-weight: 800;
        font-size: 16px;
        box-sizing: border-box;
        padding: 8px 16px;
        background: rgb(104, 66, 255);
        color: rgb(249, 250, 255);
        height: 50px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px;
        width: 50px;
        font-size: 26px;
      }
    }
  }
}
</style>